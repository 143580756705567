var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('router-link',{staticStyle:{"margin-right":"20px"},attrs:{"to":{ name: 'StatisticsCrawlers' }}},[_vm._v("zurück")])],1),_c('v-col',[_c('v-dialog',{attrs:{"width":"300px"},model:{value:(_vm.dialogDate),callback:function ($$v) {_vm.dialogDate=$$v},expression:"dialogDate"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.changePeriod()}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-text-field',{attrs:{"label":"Date range","prepend-icon":"mdi-calendar","readonly":""},on:{"click":function($event){return _vm.clickChangePeriod()}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}}),_c('v-btn',{attrs:{"text":"","color":"primary","block":"","small":""},on:{"click":function($event){return _vm.setTimeToAll()}}},[_vm._v(" all time ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":'status range period'},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-data-table',{staticClass:"unvergessen-shadow",staticStyle:{"margin-top":"20px"},attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":-1},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.pushToPaper(item.url)}}},[_vm._v(" link ")])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(new Date(item.timestamp).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) + ' ' +new Date(item.timestamp).toLocaleTimeString('de-DE'))+" ")])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.notes)+" ")])]}},{key:"item.timeoutErr",fn:function(ref){
var item = ref.item;
return [(item.timeoutErr === true)?_c('div',[_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-check")])],1):_c('div',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-close")])],1)]}},{key:"item.httpErr",fn:function(ref){
var item = ref.item;
return [(item.httpErr === true)?_c('div',[_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-check")])],1):_c('div',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-close")])],1)]}},{key:"item.selector",fn:function(ref){
var item = ref.item;
return [(item.selector === true)?_c('div',[_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-check")])],1):_c('div',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-close")])],1)]}},{key:"item.redirect",fn:function(ref){
var item = ref.item;
return [(item.redirect === true)?_c('div',[_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-check")])],1):_c('div',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-close")])],1)]}},{key:"item.seen",fn:function(ref){
var item = ref.item;
return [(item.seen === true)?_c('div',[_c('v-icon',{staticClass:"green--text"},[_vm._v("mdi-check")])],1):_c('div',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-close")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }