<template>
  <div>
    <v-row>
      <v-col>
        <router-link style="margin-right: 20px;" :to="{ name: 'StatisticsCrawlers' }">zurück</router-link>
      </v-col>
      <v-col>
        <v-dialog
        v-model="dialogDate"
        width="300px">
          <v-date-picker
            v-model="dates"
            range
            @change="changePeriod()"
          ></v-date-picker>
        </v-dialog>
        <v-text-field
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
          readonly
          @click="clickChangePeriod()"
        >
        </v-text-field>
        <v-btn text color="primary" block small @click="setTimeToAll()">
          all time
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-switch
          v-model="status"
          :label="'status range period'"
        ></v-switch>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      class="unvergessen-shadow"
      :items-per-page="-1"
      @click:row="handleClick"
      style="margin-top: 20px;"
    >
      <template v-slot:item.url="{ item }">
        <div style="cursor: pointer" @click.stop="pushToPaper(item.url)">
          link
        </div>
      </template>
      <template v-slot:item.timestamp="{ item }">
        <div style="max-width: 200px;">
          {{new Date(item.timestamp).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) + ' ' +new Date(item.timestamp).toLocaleTimeString('de-DE')}}
        </div>
      </template>
      <template v-slot:item.notes="{ item }">
        <div style="max-width: 200px;">
          {{item.notes}}
        </div>
      </template>
      <template v-slot:item.timeoutErr="{ item }">
        <div v-if="item.timeoutErr === true">
          <v-icon class="green--text">mdi-check</v-icon>
        </div>
        <div v-else>
          <v-icon class="red--text">mdi-close</v-icon>
        </div>
      </template>
      <template v-slot:item.httpErr="{ item }">
        <div v-if="item.httpErr === true">
          <v-icon class="green--text">mdi-check</v-icon>
        </div>
        <div v-else>
          <v-icon class="red--text">mdi-close</v-icon>
        </div>
      </template>
      <template v-slot:item.selector="{ item }">
        <div v-if="item.selector === true">
          <v-icon class="green--text">mdi-check</v-icon>
        </div>
        <div v-else>
          <v-icon class="red--text">mdi-close</v-icon>
        </div>
      </template>
      <template v-slot:item.redirect="{ item }">
        <div v-if="item.redirect === true">
          <v-icon class="green--text">mdi-check</v-icon>
        </div>
        <div v-else>
          <v-icon class="red--text">mdi-close</v-icon>
        </div>
      </template>
      <template v-slot:item.seen="{ item }">
        <div v-if="item.seen === true">
          <v-icon class="green--text">mdi-check</v-icon>
        </div>
        <div v-else>
          <v-icon class="red--text">mdi-close</v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      data: [],
      headers: [
        { text: 'type', value: 'type' },
        { text: 'timestamp', value: 'timestamp' },
        { text: 'timeoutErr', value: 'timeoutErr' },
        { text: 'httpErr', value: 'httpErr' },
        { text: 'selector', value: 'selector' },
        { text: 'redirect', value: 'redirect' },
        { text: 'seen', value: 'seen' },
        { text: 'url', value: 'url' },
        { text: 'notes', value: 'notes' }
      ],
      dates: [],
      dialogDate: false,
      status: false
    }
  },
  methods: {
    handleClick (value) {
      this.$router.push({ name: 'StatisticsCrawlersErrorLogDetailedView', query: { id: value._id } })
    },
    pushToPaper (url) {
      window.open(url, '_blank')
    },
    changePeriod () {
      axios.post('/crawler/error/logs', {
        status: this.status,
        period: {
          start: new Date(this.dates[0]),
          end: new Date(this.dates[1])
        }
      })
        .then(res => {
          this.data = res.data.errors
        })
        .catch(() => {})
    },
    setTimeToAll () {
      axios.post('/crawler/error/logs', { body: {} })
        .then(res => {
          this.data = res.data.errors
          this.dates = []
        })
        .catch(() => {})
    },
    clickChangePeriod () {
      var dateEnd = new Date(Date.now())
      var dateStart = new Date()
      dateStart.setDate(dateStart.getDate() - 2)
      this.dates[0] = new Date(dateStart).toISOString().split('T')[0]
      this.dates[1] = new Date(dateEnd).toISOString().split('T')[0]
      this.dialogDate = true
    }
  },
  created () {
    this.setTimeToAll()
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  }
}
</script>
